<template>

		<div class="content is-flex is-flex-direction-column is-full mb-6 pb-6">

			<h1>Einverstanden - das Sprachspiel</h1>
			<div class="pb-6 is-clearfix">
				<img class="e-card is-pulled-left-tablet mr-6 mb-3" src="//assets.entfalter.de/img/cards-marketing/lebensrolle-6.jpg"/>
				<div class="is-block-mobile">
					<p>Die Wortwolken auf den Karten regen an, aktiv eigene Sätze zu (er-)finden, die die Aussage und das Bild der Karte in eigene Worte fassen. So kommt Prägendes, Persönliches, Soziales, Irritierendes . . . zur Sprache.
						<br/>
					   Die Wortwolken auf allen Karten können auf diese Weise zum Anlass werden, die Elemente, aus denen sich das Spiel des Lebens zusammensetzt, in andere Sprachen zu übertragen bzw. die entsprechenden Worte in anderen Sprachen aufzusuchen.
						<br/>
						<strong>So kann Das Sprachspiel zum Lernen einer fremden Sprache genutzt werden</strong>, wie es auch dabei helfen kann, aus einer anderen Sprache lebensnah, aufgaben- und berufsbezogen und doch spielerisch in die deutsche Sprache einzumünden.</p>
				</div>

			</div>

		</div>



</template>

<script>
export default {
	name: 'spielRollen',


}
</script>
<style lang="scss">


</style>
