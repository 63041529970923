<template>

	<div class="content is-flex is-flex-direction-column is-full mb-6 pb-6">

		<h1>Hausgemacht - das Biografiespiel</h1>
		<div class="">
			<img class="is-pulled-left-tablet mr-6 mb-3" src="//assets.entfalter.de/img/cards-marketing/set-bio.jpg"/>
			<p>
				Mit den sechs Kartentypen also mit 138 Karten und den darauf verfügbaren Wortwolken lassen sich eigene und fremde, ernste und heitere, mutige und verhaltene Lebensgeschichten schreiben, rekonstruieren, aus-probieren und ausdenken.
				So lässt sich mit Max Frisch sagen und verwirklichen, was in seinem Roman ‚Mein Name sei Gantenbein‘ steht:
				Ich stelle mir vor, ... und darin mündet, Geschichten anzuziehen wie Kleider.<br/><br/>

				Wenn man das macht, bietet sich die Chance, mit vielen biografischen Chancen und Risiken spielerisch umzugehen. Man testet die Vielzahl der Möglichkeiten und Freiheiten, die wir als moderne Individuen haben.
				<br/>
				Im aktiv Geschichten erzählen, erfinden und erraten wird die Fülle der Entfalter-Karten dabei zum Werkzeugkasten für das Herstellen von eigenen Geschichten bezogen auf das Spiel des Lebens. Das ist dann ein aktives Gestalten von ‚Bastelbiografien‘, die – zunächst spielerisch und dann auch real – Wirklichkeit werden könnten.
				<br/><br/>
				<i>
					Der Liedermacher und Autor Felix Meyer hat’s probiert: Ich stelle mir vor, das Leben wäre ein Spiel, in dem man sich durch verschiedene Versionen von sich selbst durch denkt, hier eine Abzweigung in diese, dort eine in jene Richtung nimmt und am Ende etwas kann, was man vorher nicht konnte: zum Beispiel zu formulieren, was man sein will. Unter welchen Umständen und mit wem man vielleicht jemand anders sein könnte, größer oder kleiner werden würde. Ich stelle mir vor, dass man sich das überhaupt aussuchen kann: größer oder kleiner zu sein. Leiser oder lauter. Stärker oder zurückhaltender.
					<br/>
					Ich stelle mir vor, wie ich lieber alleine vorne stehe oder mit anderen zusammen. Wer ich bin. Oder wer ich in dem Spiel gerne gewesen wäre. Wer ich in einem Theaterstück sein könnte. In einem Gemälde. Oder in einem Film.
					Auf der Bühne oder dahinter. Ich stelle mir vor, in diesem Spiel gäbe es keine Verlierer. Man könne, während man es spielt, nur gewinnen. Ich stelle mir vor, dass mein Leben, während ich dabei bin, Wünsche zu formulieren, eine Form annimmt, die diesen Wünschen mehr und mehr entspricht. Ich stelle mir vor, wie ich lieben will und wie ich helfen kann. Wie mir geholfen wird und wie ich geliebt werde.
					<br/>
					Ich stelle mir vor, ich sei ein Mensch, der gerne draußen ist. Der Luft um die Nase braucht, egal, ob sie kalt ist oder warm. Eine Klimaanlage schlimmer findet als drei Stunden im Nieselregen zu stehen. Ich stelle mir vor, dass ich mit diesen Eigenschaften Energie erzeugen kann, ohne, dass etwas verbrannt werden muss, eine Kernreaktion herbeigeführt, etwas hergestellt, das dann die Sonnenenergie oder den Wind einsammelt oder irgendwer für mich auf einem Fahrrad strampelt.
					<br/>
					Ich stelle mir vor, wie ich Bäume pflanze. Dass ich, wenn ich gleich morgen anfinge, so viele Bäume pflanzen könnte wie nie ein Mensch zuvor. Ich stelle mir vor, dass ich das, was ein Mensch gepflanzt hat, ernte und daraus für 23 weitere Menschen Essen koche. Oder waren es 123? Ich stelle mir vor, dass das Essen besser wird, wenn es nur 23 sind.
					Oder noch ganz anders, dass ich gerne in einem Büro bin oder mir lange Arbeitszeiten und weite Reisen nichts ausmachen. Im Gegenteil, dass ich es genieße, unterwegs zu sein, andere Sprachen zu sprechen und anderes Essen zu essen. Dass es mich nicht frustriert, zu scheitern.
					Dass es mich stärker macht, schwach zu sein. Dass es im Leben mehr gute Fragen braucht als Antworten.
					Ich stelle mir vor, ich bliebe besser alleine. Oder ich könnte jeden Gedanken erst einmal nehmen, drehen und wenden und dann auch wieder verwerfen. Ich stelle mir vor, ich hätte Menschen an meiner Seite, die ich liebe. Ich stelle mir mich mit Kindern vor. Ich stelle mir vor, ich ginge durch die Welt wie durch ein Museum, ein Konzert oder einen Roman und sähe mir gerne an, wie andere Menschen diese Welt gestalten oder gestaltet haben. Ich stelle mir vor, wie gutes Essen riecht oder ein ganzer Marktplatz. Ich stelle mir vor, ich bräuchte neue Schuhe. Oder einen Mantel.
					<br/>
					Ich stelle mir vor, ich unterhielte mich stundenlang mit anderen Menschen darüber, wie sie sich das Leben vorstellen.
					Ich stelle mir vor, das Leben wäre ein Spiel.
				</i>
				<br/><br/><br/>
				www.felixmeyer.eu
			</p>
		</div>

	</div>


</template>

<script>

export default {
	name: 'spielBio',


}
</script>
<style lang="scss">


</style>
