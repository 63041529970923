<template>
	<div>
		<div @click="toggleNav" v-if="asNav">
			<div :class="['bar is-nav is-size-4 mt-4', currentNavStyle]">
				<p class="is-uppercase m-0">Wähle deine Spielvariante:</p>
				<strong class="has-text-white">{{ currentNav }}</strong>

				<span :class="['indicator jam jam-arrow-square-up', navOpen ? 'open' : '']"></span>

			</div>
		</div>
		<div v-show="!asNav || navOpen">
			<div class="is-clearfix">
				<div @click="routeTo('/kompass/ausgewaehlt','Das Berufsentwicklungsspiel', 'bg-beruf')" class="bar bg-beruf is-size-4 mt-1">
					<strong class="has-text-white">Ausgewählt</strong>
					Das Berufsentwicklungsspiel
				</div>

			</div>

			<div class="is-clearfix">
				<div @click="routeTo('/kompass/aufgeraeumt','Das Rollenspiel', 'bg-rollen')" :class="['bar bg-rollen is-size-4 mt-1']">
					<strong class="has-text-white">Aufgeräumt</strong>
					Das Rollenspiel
				</div>
				<div class="pb-6 is-clearfix" v-show="expand">
					<!--					-->
					<!--					<h3>Man bekommt heraus, wo und wie man im Leben steht und stehen könnte.</h3>-->
					<!--					<h4> Anleitung für das Kartenspiel "Erlebt & aufgeräumt"</h4>-->

					<!--					<strong>Ziel des Spiels</strong>-->
					<!--					<p>Spieler*innen erkunden und diskutieren verschiedene Rollenerwartungen, Gefühle und innere Stimmen, um ein tieferes Verständnis für unterschiedliche Lebensrollen zu entwickeln. Empathie und Austausch stehen im Vordergrund.</p>-->

					<!--					<strong>Spielmaterial</strong>-->
					<!--					<p>-->
					<!--						- Lebensrollenkarten-->
					<!--						- 24 Gefühlskarten-->
					<!--					</p>-->

					<!--					<strong>Spielvorbereitung</strong>-->
					<!--					<p>-->
					<!--						- Lebensrollenkarten verdeckt stapeln.<br/>-->
					<!--						- Gefühlskarten offen in einem 4x6-Raster auslegen.-->
					<!--					</p>-->

					<!--					<strong>Spielablauf</strong>-->
					<!--					<p>1. Beginn einer Spielrunde:</p>-->
					<!--					<p>Eine Spielperson deckt die oberste Lebensrollenkarte auf und legt sie in die Mitte. Sie beschreibt die Lebensrolle, unterstützt durch die Wortwolke auf der Karte.</p>-->

					<!--					<p>-->
					<!--						2. Auswahl der Gefühlskarten:</p>-->
					<!--					<p>- Die Spielperson wählt eine Gefühlskarte aus, die ein starkes Gefühl in Bezug auf die Lebensrolle repräsentiert, und erklärt ihre Wahl.</p>-->
					<!--					<p>- Im Uhrzeigersinn folgt die nächste Person und wählt ebenfalls eine Gefühlskarte aus, die sie neben die Lebensrollenkarte legt und erläutert.</p>-->

					<!--					<p>3. Ende einer Spielrunde:</p>-->
					<!--					Wenn alle Spielenden ihre Emotionen zur Lebensrolle ausgedrückt haben, legen sie die Gefühlskarten zurück ins Raster und die Lebensrollenkarte offen neben den Stapel. Die nächste Runde beginnt mit einer neuen Lebensrollenkarte.-->

					<!--					<p>Spielende:</p>-->
					<!--					<p>Das Spiel endet, sobald alle Lebensrollenkarten besprochen wurden.</p>-->

					<!--					<p>Spielgewinn:</p>-->
					<!--					<p>Der Gewinn liegt im vielfältigen Austausch und der Entwicklung von Empathie und Verständnis für unterschiedliche Lebensrollen.</p>-->

					<!--					<p>Alleinvariante:</p>-->
					<!--					<p>Das Spiel kann auch alleine gespielt werden, indem man sich selbst durch die Gefühlskarten in Beziehung zu den Lebensrollenkarten setzt.</p>-->
				</div>
			</div>

			<div class="is-clearfix">
				<div @click="routeTo('/kompass/hausgemacht','Das Biografiespiel', 'bg-bio')" class="bar bg-bio is-size-4 mt-1">
					<strong class="has-text-white">Hausgemacht</strong>
					Das Biografiespiel
				</div>
			</div>

			<div class="is-clearfix">
				<div @click="routeTo('/kompass/einverstanden','Das Sprachspiel', 'bg-sprachen')" class="bar bg-sprachen is-size-4 mt-1">
					<strong class="has-text-white">Einverstanden</strong>
					Das Sprachspiel
				</div>
			</div>
		</div>

	</div>
</template>
<script>
export default {
	name:  'CardGamesComponent',
	props: {
		// Expand child content @deprecated
		expand: {
			type:    Boolean,
			default: false
		},
		// Act as interactive navigation, change styles according to route
		asNav: {
			type:    Boolean,
			default: true
		}
	},
	data() {
		return {
			navOpen:         true,  // initially open
			currentNav:      '',
			currentNavStyle: 'is-nav'
		}
	},
	methods: {
		toggleNav() {
			this.navOpen = !this.navOpen
		},
		routeTo(to, title, style) {
			if (!this.$props.asNav) return false
			this.currentNav = title
			this.currentNavStyle = style
			this.toggleNav()
			this.$router.push(to).catch(() => {})
		}
	},
	mounted() {
		// Check if referred to:
		switch (this.$route.name) {
			case 'berufsspiel':
				this.currentNav = 'Das Berufsentwicklungsspiel'
				this.currentNavStyle = 'bg-beruf'
				this.navOpen = false
				break

			default:
				break;
		}
	}
}
</script>

<style lang="scss">


</style>
