<template>
	<div class="payment">
		<div v-show="status.message" ref="message" :class="['message has-text-weight-bold', status.error && 'is-error']" v-html="status.message"></div>

		<entfalter-button v-show="!showPayment"
		                  @click="togglePayment" class="is-pulled-right">
			Jetzt Kaufen
		</entfalter-button>

		<div class="mt-2 pt-2">
			<input
				type="checkbox"
				id="dataprotection_accepted"
				v-model="dataprotectionAccepted"
				class="form-control"
			/>

			<label style="text-align:left;margin-left:0.5em;" for="dataprotection_accepted">Ich habe die <a href="#widerruf">Widerrufsbelehrung</a> und die <a href="#" v-on:click.prevent="showTerms()">Datenschutzbestimmungen gelesen</a> und bin damit einverstanden.</label><br/>
		</div>

		<div v-show="showPayment">
			<div ref="paypal"></div>
			<div id="paypal-button-container"></div>
		</div>
	</div>
</template>

<script>


import EntfalterButton from "@/components/entfalter-button.component.vue";
import axios           from "axios";

/**
 * Simple Paypal Payment wrapper template
 * It uses the PaymentLib script
 *
 * @property {('meat' | 'veggie' | 'other')} type - The food's type
 * @component
 */
export default {
	name:       'PaymentLib',
	components: {EntfalterButton},

	props: {
		amount:         {
			type:    String,
			default: "0"
		},
		quantity:       {
			type:    Number,
			default: 1
		},
		dataprotection: {
			type:    Boolean,
			default: false
		}
	},

	created() {
		const script = document.createElement("script");
		script.src = process.env.VUE_APP_PP_URL;
		script.addEventListener("load", this.setLoaded);
		document.body.appendChild(script);

	},

	data() {
		return {
			showPayment:            false,
			dataprotectionAccepted: false,
			// Component Status [would be an interface in 'real' code]
			status: {
				progressing: false,
				success:     false,
				cancelled:   false,
				error:       false,
				message:     '',
				order:       {}
			}
		}
	},

	methods: {
		togglePayment() {
			this.status.message = ''
			this.status.error = false
			// Check DataProt.
			if (!this.dataprotectionAccepted) {
				this.status.error = true
				this.status.message = 'Bitte bestätige unsere Datenschutzerklärung!';
				return
			}
			this.status.error = false
			this.status.message = ''
			this.showPayment = !this.showPayment
			if (this.status.error || this.status.success) this.showPayment = false
		},

		setLoaded: function () {
			//this.loaded = true;
			window.paypal
			      .Buttons({
				      createOrder: (data, actions) => {
					      this.status.progressing = true
					      return actions.order.create({
						      purchase_units: [
							      {
								      description: 'Entfalter - Das Spiel',
								      amount:      {
									      currency_code: "EUR",
									      value:         this.amount,
									      quantity:      this.quantity
								      }
							      }
						      ]
					      });
				      },
				      onCancel:    async () => {
					      this.status.cancelled = true;
					      this.status.progressing = false
					      this.status.message = 'Der Vorgang wurde abgebrochen'
					      this.$emit('payment-update', this.status)
				      },
				      onApprove:   async (data, actions) => {
					      const order = await actions.order.capture();
					      this.status.success = true;
					      this.status.progressing = false
					      this.status.order = order
					      this.order = order; // @deprecate
					      return actions.order.capture().then(() => {
						      this.sendPayment(this.status)
						      //alert('Transaction completed by ' + details.payer.name.given_name);
					      });
				      },
				      onError:     err => {
					      this.status.progressing = false
					      this.status.error = true
					      this.status.message = 'Ein Fehler ist aufgetreten'
					      this.$emit('payment-update', this.status)
					      console.warn(err)
				      }
			      })
			      .render(this.$refs.paypal);
		},

		async sendPayment(status) {

			const server = process.env.VUE_APP_API_URL;
			this.showPayment = false;
			this.status = {...this.status, ...status};
			this.$emit('statuschange', {success: false, progressing: true, error: false})
			if (this.status.success && this.status.order) {
				// NOTE: this relies on a nested object with arrays --> and a SINGLE item!
				const payId = status.order.purchase_units[0].payments.captures[0].id
				const payMail = status.order.payer.email_address
				const payType = 128
				const payAmount = this.$props.quantity
				const address = {...status.order.purchase_units[0].shipping.address, ...{name: this.status.order.payer.name.given_name + ' ' + this.status.order.payer.name.surname}}
				try {
					// Call our server to save the transaction
					await axios.post(`${ server }?func=entfalter&task=payment`, {
						payId:       payId,
						payMail:     payMail,
						payType:     payType,
						payAmount:   payAmount,
						payShipping: address
					})


					this.status.message =
						"Vielen Dank, " + this.status.order.payer.name.given_name + "! Der Vorgang war erfolgreich. Wir schicken dir umgehend das Spiel zu.";
					this.showPayment = false;
					this.$emit('statuschange', {success: true, progressing: false, error: false})
				} catch (error) {
					this.error = true;
					this.showPayment = true;
					this.status.message = 'Der Bezahlvorgang konnte leider nicht abgeschlossen werden. Bitte versuche es erneut oder wende Dich an <a href="mailto:info@entfalter.de">uns via Email</a>.';
					this.$emit('statuschange', {success: false, progressing: false, error: true})
					throw error;
				}
				this.status.progressing = false;
				this.status.complete = true;
			}
		},

		showTerms() {
			window.open('https://entfalter.de/datenschutz')

		},
	}
}

</script>

<style>
.payment {
	margin     : 2rem auto;
	text-align : center;
	/* width: 50px;
	height: 100px; */
}

.message {
	color      : white;
	background : rgb(86, 155, 57);

	&.is-error {
		background : rgb(255, 136, 0);
	}
}

</style>
