<template>
	<div class="page content has-text-left">
		<section class="section container pt-0">

			<div class="content">

				<div class="columns left">
					<div class="column is-full-mobile">
						<h1 class="m-0">Entfalter <sup style="font-style: italic">compass</sup></h1>
						<h2 class="m-0">App, Karten und Spiele</h2>
					</div>
					<div class="column is-full-mobile">
						<ul class="flat is-flex is-size-6 has-text-weight-bold">
							<li class="mr-2 my-0"><a href="#kaufen" class="has-text-weight-bold has-text-black">Kaufen</a></li>
							<li class="mr-2 my-0"><a href="#anleitung" class="has-text-weight-bold has-text-black">Mehr erfahren</a></li>
							<li class="mr-2 my-0">
								<router-link to="/kompass" class="has-text-weight-bold has-text-black">Du hast es schon?</router-link>
							</li>
						</ul>
					</div>
				</div>


				<a id="kaufen"/>
				<div class="columns">
					<div class="column">
						<img src="/img/kartenbox.jpg" width="580"/>
					</div>
					<div class="column has-text-left">
						<h2>Der Entfalter - Ein Kompass im Spiel des Lebens</h2>
						<div class="is-size-5">
							Der Entfalter ist ein Kartenset mit einer App. Er wird zum Kompass im Spiel des Lebens, weil man mit seinen Karten persönliche Erfahrungen, Gefühle, Geschichten, Wissen und Wünsche 4fach spielend versammeln und und variieren kann.
						</div>
						<CardGamesComponent :as-nav="true"/>
						<br/>

						<div class="right" v-show="showPayment">
							<div class="pr-5">All das – inklusive dem Zugang zur Entfalter-App kostet</div>

							<div class="columns is-mobile mt-3 is-vcentered">
								<div class="column">
									für
								</div>
								<div class="column px-0 is-1">
									<input @click="changeQuantity(-1)" type='button' class="button" value="-"/>
								</div>
								<div class="column px-0 is-1">
									<input
										type="text"
										class="center"
										v-model="orderQty"
										style="width: 100%; font-size: 18px;"
									/>
								</div>
								<div class="column px-0 is-1 has-text-left">
									<input @click="changeQuantity(1)" type='button' class="button" value="+"/>
								</div>
								<div class="column has-text-left">
									&nbsp;Set<span v-show="orderQty !== 1">s</span>
								</div>
								<div class="column is-6">
									<div class="is-size-3 has-text-weight-bold pr-5">{{ orderAmount.replace('.', ',') }} EUR</div>
									<div class="pr-5 has-text-weight-bold">inkl. Versand</div>

								</div>
							</div>

						</div>
						<PaymentLib :quantity="orderQty" :amount="orderAmount" @statuschange="onStatusChange"/>


					</div>
				</div>


			</div>

		</section>

		<a id="anleitung"/>


		<section class="section m-0 p-0 is-clearfix">
			<div class="container py-2">
				<h2>So wird der Entfalter zu Deinem Kompass</h2>
				<h3 class="m-0">Die 6 Spielkartentypen</h3>
				<p>
					Der Entfalter hilft dabei, auf den persönlichen Lebenswegen offen und frei unterwegs zu sein und sich dabei nicht richtungslos sondern zunehmend orientiert zu erleben.

					So wird er zu Deinem Kompass:
					Sechs Spielkartentypen und die passende App erlauben, Lebenserfahrungen und Lebens-erwartungen sowie die vielen Möglichkeiten zur Lebensentfaltung darzustellen und anzusehen.
					Sie lassen sich im eigenen Kopf und in Gesprächen bearbeiten. Das hilft, bei sich zu sein und zu bleiben, etwas in die Hand zu nehmen, zu verändern und sein Leben gezielt zu erweitern.
				</p>

				<swiper
					:options="swiperOptions"
					class="has-text-centered"
				>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/aufgaben.png"/>
						<p class="mt-4 mx-4">
							<strong>Die Aufgabenkarte</strong> ist eine von neun Aufgaben, die sich im Leben stellen.
						</p>
					</swiper-slide>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/rollen.png"/>
						<p class="mt-4 px-4">
							<strong>Die Rollenkarte</strong> ist eine von 24 grundlegenden Rollen, die man in einem Beruf einnehmen kann.
						</p>
					</swiper-slide>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/welten.png"/>
						<p class="mt-4 px-4">
							<strong>Die Weltenkarte</strong> zeigt eine von vier Welten, in denen wir uns bewegen.
						</p>
					</swiper-slide>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/arbeitsplatz.png"/>
						<p class="mt-4 px-4">
							<strong>Die Arbeitsplatzkarte</strong> zeigt die Merkmale eines Arbeitsplatzes auf. Das sind 38.
						</p>
					</swiper-slide>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/lebensrolle.png"/>
						<p class="mt-4 px-4">
							<strong>Die Lebensrollen-Karte</strong> stellt dar, in welche Rolle man im Leben schlüpfen kann.
						</p>
					</swiper-slide>
					<swiper-slide>
						<img class="e-card" src="//assets.entfalter.de/img/cards-marketing/teamkarte.png"/>
						<p class="mt-4 px-4">
							<strong>Die Teamkarte</strong> ist eine, die das Innere Team nach Schultz von Thun darstellt.
						</p>
					</swiper-slide>

				</swiper>
				<div class="swiper-button-prev is-hidden-mobile is-hidden-desktop" slot="button-prev"></div>
				<div class="swiper-button-next is-hidden-mobile is-hidden-desktop" slot="button-next"></div>
				<div class="swiper-pagination is-hidden-desktop" slot="pagination"></div>


			</div>
		</section>

		<section class="section is-clearfix">
			<div class="container py-2">
				<h2>Die Spielmöglichkeiten</h2>

				<div>
					<CardGamesComponentOverview/>
				</div>
			</div>
		</section>

		<section class="section is-clearfix" style="margin: 0 auto;border-top:1px dotted">
			<div class="container py-2 small">
				<a id="widerruf"/>
				<div>
				<h1><strong>Widerrufsbelehrung</strong></h1>

				<h2><strong>Widerrufsrecht</strong></h2>
				<p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>

				<p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat. <strong>Ihr Widerrufsrecht erlischt mit der Inanspruchnahme des beigefügten Entfalter-Codes.</strong></p>

				<p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
				</p>

				<p>
					Richten Sie Ihren Widerruf an Omnimundi GmbH & Co. KG mittels:
					<ul>
						<li>Post an <strong>Schönebecker Straße 51, 39104 Magdeburg</strong>, oder</li>
						<li>Email an info@omnimundi.de, oder</li>
						<li>Telefon 0391/50380903</li>

					</ul>
				</p>

				<p>
					Sie können dafür das beigefügte <a href="https://entfalter.de/assets/Widerrufsformular.pdf">Muster-Widerrufsformular</a> verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
				</p>

				<h2><strong>Folgen des Widerrufs</strong></h2>

				<p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstige Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>

				<p>Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</p>

				<p>Sie haben die Waren unverzüglich in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.

					<br/>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
				</div>
			</div>
		</section>
	</div>

</template>

<script>

import CardGamesComponent         from "@/components/CardGames.vue";
import CardGamesComponentOverview from "@/components/CardGamesOverview.vue";
import PaymentLib                 from "@/components/PaymentLib.vue";
import { swiper, swiperSlide }    from 'vue-awesome-swiper'

export default {
	name: 'spiel',

	components: {
		CardGamesComponentOverview,
		CardGamesComponent,
		PaymentLib,
		swiper,
		swiperSlide
	},

	data() {
		return {
			showPayment:   true,
			baseAmount:    24.99,
			orderQty:      1,
			swiperOptions: this.$utils.getSwiperOptions()
		}
	},

	mounted() {

	},

	computed: {
		orderAmount() {
			return (this.baseAmount * this.orderQty).toFixed(2)
		}
	},

	methods: {

		changeQuantity(value) {
			this.orderQty = Math.max(0, this.orderQty + value);
		},

		createMail(mail) {
			return `<a href="mailto:${ mail }">Email</a>`
		},

		onStatusChange(e) {

			const {success, progressing} = e
			if (success || progressing) {
				this.showPayment = false
			} else
				this.showPayment = true
		}


	},
	beforeDestroy() {
		// this.$refs.mySwiper.destroy()    // AutoDestroy is defaulted to True
	}

}
</script>
<style lang="scss" scoped>

ul.flat {
	list-style : none;
	margin     : 0;
	}


</style>
