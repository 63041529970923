<template>

	<div class="columns is-multiline">

		<div class="column is-6-tablet" >
			<div class="bar bg-beruf is-size-4 mt-1 mb-6">
				<strong class="has-text-white">Ausgewählt</strong>
				Das Berufsentwicklungsspiel
			</div>
			<div class="pb-6 is-clearfix">
				<img class="is-pulled-left-tablet mr-3 mb-3" src="//assets.entfalter.de/img/cards-marketing/set-berufsentfaltung.jpg"/>
				<p>Man kann per Karten sowie mit der EntfalterApp individuelle Profile erstellen, indem man persönliche Vorlieben, Interessen und Aktionspotenziale zu Aufgaben, Weltbezügen, Rollen und Arbeitsplätzen als Kartenbild legt.</p>
				<p>
					<strong>Allein:</strong> All das kann man – als Besitzer des Entfalters – auch in der App anlegen und erhält dadurch ein persönliches Entfaltungsprofil zu den Bereichen Aufgaben, Rollen, Weltbezüge und Arbeitsplatzvorstellungen.
				</p>
				<p><strong>Mit vielen:</strong> Unterstützt durch die vielen Referenzprofile von Berufen, Ausbildungen und Ehrenämtern, die in der Entfalter-App dargestellt
				   sind, kann man mit den Karten – allein oder mit anderen – Berufe, Ausbildungsangebote aber auch Stellenprofile von den Menschen am Tisch auslegen und ansehen.
				</p>
				<p> Sie werden damit bewusst, für alle konkreter vorstellbar und lassen sich für einen Austausch und Diskussionen mit einander vor Augen führen.
				    Das geht auch allein, ist aber in der Familie, mit Freunden und auch in größeren Runden, wie in einer Klasse, besonders interessant, weil so verschiedene Sichtweisen ins Spiel kommen.
				</p>
				<p><strong>Als Spiel:</strong> Mit den Karten gelegte Profile lassen sich auch raten; man kann sie prüfend ansehen und diskutieren sowie mit den Profilen in der Entfalter-App abgleichen und dabei ggf. das bestehende Verständnis korrigieren und erweitern. Wenn man mag, kann man – ebenfalls spielerisch – die Profile von (öffentlichen) Personen darstellen, die man in seinem Umfeld oder aus Funk, Film, Fernsehen und Medien kennt. Sie lassen sich raten und diskutieren.
				</p>
				<p><strong>Für sich persönlich:</strong> Als der-/ diejenige, der/die das persönliche Entfaltungsprofil in der App angelegt hat, kann man dieses Profil für Matchings mit den Profilen von Berufen, Ausbildungen etc. nutzen und sieht so, welche Fülle an Möglichkeiten sich einem bietet.
				</p>
				<p>
					Das ist ein wichtiger Baustein, aus dem sich der persönliche Kompass in beruflicher Hinsicht ergibt, weil im Matching die individuellen Entfaltungsmöglichkeiten und -chancen sichtbar und diskutierbar werden. Denn in den entstehenden Matchings zeigt sich gut nachvollziehbar, was in welchen Prozentsätzen zum eigenen Profil passen könnte. Außerdem sieht man, woraus sich eine solche Passung genauer ergibt oder warum sie eher nicht entsteht.
				</p>
			</div>
		</div>

		<div class="column is-6-tablet">
			<div :class="['bar bg-rollen is-size-4 mt-1 mb-6']">
				<strong class="has-text-white">Aufgeräumt</strong>
				Das Rollenspiel
			</div>
			<div class="pb-6 is-clearfix">
				<img class="is-pulled-left-tablet mr-3 mb-3" src="//assets.entfalter.de/img/cards-marketing/set-rollenspiel.jpg"/>
				<p>Durch eine zu treffende Wahl aus dem Angebot der 24 alltagsrollen- und 24 gefühlsbezogenen Karten bekommt man heraus, wie man gerade in den alltäglichen Lebensrollen unterwegs ist und was insofern das persönliche Rollenpotential ausmacht.
				   Im Blick auf die Karten entsteht Übersicht und Ordnung sowie Ideen dazu, wie man sein Rollengefüge und Inneres Gefühlsteam stärken und vielleicht auch gezielter einsetzen kann.
				   Sein Inneres Team – wie der Kommunikationsforscher Schulz von Thun das nennt – zu kennen und einzuschätzen, hilft einem, seinen inneren Kompass, der manchmal wenig bewusst ist, zu kennen, ihn zu überprüfen und womöglich neu zu justieren.
				   Dazu lässt sich im spielerischen Tun, beim Rollen einnehmen und innere Teammitglieder verschieden zuordnen, herausfinden, was passt, was wie wirkt, was sich gut anfühlt und was einem und anderen gefällt.
				   Man sieht die große Zahl möglicher
				   Rollenspiele.</p>

			</div>
		</div>

		<div class="column is-6-tablet">
			<div class="bar bg-bio is-size-4 mt-1 mb-6">
				<strong class="has-text-white">Hausgemacht</strong>
				Das Biografiespiel
			</div>
			<div class="pb-6 is-clearfix">
				<img class="is-pulled-left-tablet mr-3 mb-3" src="//assets.entfalter.de/img/cards-marketing/set-bio.jpg"/>
				<p>
					Mit den sechs Kartentypen also mit 138 Karten und den darauf verfügbaren Wortwolken lassen sich eigene und fremde, ernste und heitere, mutige und verhaltene Lebensgeschichten schreiben, rekonstruieren, aus-probieren und ausdenken.
					So lässt sich mit Max Frisch sagen und verwirklichen, was in seinem Roman ‚Mein Name sei Gantenbein‘ steht:
					Ich stelle mir vor, ... und darin mündet, Geschichten anzuziehen wie Kleider.<br/><br/>

					Wenn man das macht, bietet sich die Chance, mit vielen biografischen Chancen und Risiken spielerisch umzugehen. Man testet die Vielzahl der Möglichkeiten und Freiheiten, die wir als moderne Individuen haben.
					<br/>
					Im aktiv Geschichten erzählen, erfinden und erraten wird die Fülle der Entfalter-Karten dabei zum Werkzeugkasten für das Herstellen von eigenen Geschichten bezogen auf das Spiel des Lebens. Das ist dann ein aktives Gestalten von ‚Bastelbiografien‘, die – zunächst spielerisch und dann auch real – Wirklichkeit werden könnten.
<!--					<br/><br/>-->
<!--					<i>-->
<!--						Der Liedermacher und Autor Felix Meyer hat’s probiert: Ich stelle mir vor, das Leben wäre ein Spiel, in dem man sich durch verschiedene Versionen von sich selbst durch denkt, hier eine Abzweigung in diese, dort eine in jene Richtung nimmt und am Ende etwas kann, was man vorher nicht konnte: zum Beispiel zu formulieren, was man sein will. Unter welchen Umständen und mit wem man vielleicht jemand anders sein könnte, größer oder kleiner werden würde. Ich stelle mir vor, dass man sich das überhaupt aussuchen kann: größer oder kleiner zu sein. Leiser oder lauter. Stärker oder zurückhaltender.-->
<!--						<br/>-->
<!--						Ich stelle mir vor, wie ich lieber alleine vorne stehe oder mit anderen zusammen. Wer ich bin. Oder wer ich in dem Spiel gerne gewesen wäre. Wer ich in einem Theaterstück sein könnte. In einem Gemälde. Oder in einem Film.-->
<!--						Auf der Bühne oder dahinter. Ich stelle mir vor, in diesem Spiel gäbe es keine Verlierer. Man könne, während man es spielt, nur gewinnen. Ich stelle mir vor, dass mein Leben, während ich dabei bin, Wünsche zu formulieren, eine Form annimmt, die diesen Wünschen mehr und mehr entspricht. Ich stelle mir vor, wie ich lieben will und wie ich helfen kann. Wie mir geholfen wird und wie ich geliebt werde.-->
<!--						<br/>-->
<!--						Ich stelle mir vor, ich sei ein Mensch, der gerne draußen ist. Der Luft um die Nase braucht, egal, ob sie kalt ist oder warm. Eine Klimaanlage schlimmer findet als drei Stunden im Nieselregen zu stehen. Ich stelle mir vor, dass ich mit diesen Eigenschaften Energie erzeugen kann, ohne, dass etwas verbrannt werden muss, eine Kernreaktion herbeigeführt, etwas hergestellt, das dann die Sonnenenergie oder den Wind einsammelt oder irgendwer für mich auf einem Fahrrad strampelt.-->
<!--						<br/>-->
<!--						Ich stelle mir vor, wie ich Bäume pflanze. Dass ich, wenn ich gleich morgen anfinge, so viele Bäume pflanzen könnte wie nie ein Mensch zuvor. Ich stelle mir vor, dass ich das, was ein Mensch gepflanzt hat, ernte und daraus für 23 weitere Menschen Essen koche. Oder waren es 123? Ich stelle mir vor, dass das Essen besser wird, wenn es nur 23 sind.-->
<!--						Oder noch ganz anders, dass ich gerne in einem Büro bin oder mir lange Arbeitszeiten und weite Reisen nichts ausmachen. Im Gegenteil, dass ich es genieße, unterwegs zu sein, andere Sprachen zu sprechen und anderes Essen zu essen. Dass es mich nicht frustriert, zu scheitern.-->
<!--						Dass es mich stärker macht, schwach zu sein. Dass es im Leben mehr gute Fragen braucht als Antworten.-->
<!--						Ich stelle mir vor, ich bliebe besser alleine. Oder ich könnte jeden Gedanken erst einmal nehmen, drehen und wenden und dann auch wieder verwerfen. Ich stelle mir vor, ich hätte Menschen an meiner Seite, die ich liebe. Ich stelle mir mich mit Kindern vor. Ich stelle mir vor, ich ginge durch die Welt wie durch ein Museum, ein Konzert oder einen Roman und sähe mir gerne an, wie andere Menschen diese Welt gestalten oder gestaltet haben. Ich stelle mir vor, wie gutes Essen riecht oder ein ganzer Marktplatz. Ich stelle mir vor, ich bräuchte neue Schuhe. Oder einen Mantel.-->
<!--						<br/>-->
<!--						Ich stelle mir vor, ich unterhielte mich stundenlang mit anderen Menschen darüber, wie sie sich das Leben vorstellen.-->
<!--						Ich stelle mir vor, das Leben wäre ein Spiel.-->
<!--					</i>-->
<!--					<br/><br/><br/>-->
<!--					www.felixmeyer.eu-->
				</p>
			</div>
		</div>

		<div class="column is-6-tablet">
			<div class="bar bg-sprachen is-size-4 mt-1 mb-6">
				<strong class="has-text-white">Einverstanden</strong>
				Das Sprachspiel
			</div>
			<div class="pb-6 is-clearfix">
				<img class="e-card is-pulled-left-tablet mr-3 mb-3" src="//assets.entfalter.de/img/cards-marketing/lebensrolle-6.jpg"/>
				<div class="is-block-mobile">
					<p>Die Wortwolken auf den Karten regen an, aktiv eigene Sätze zu (er-)finden, die die Aussage und das Bild der Karte in eigene Worte fassen. So kommt Prägendes, Persönliches, Soziales, Irritierendes . . . zur Sprache.
						<br/>
					   Die Wortwolken auf allen Karten können auf diese Weise zum Anlass werden, die Elemente, aus denen sich das Spiel des Lebens zusammensetzt, in andere Sprachen zu übertragen bzw. die entsprechenden Worte in anderen Sprachen aufzusuchen.
<br/>
						<strong>So kann Das Sprachspiel zum Lernen einer fremden Sprache genutzt werden</strong>, wie es auch dabei helfen kann, aus einer anderen Sprache lebensnah, aufgaben- und berufsbezogen und doch spielerisch in die deutsche Sprache einzumünden.</p>
				</div>
<!--				<img class="is-pulled-left-tablet mr-3 mb-3" src="//assets.entfalter.de/img/cards-marketing/sprachspiel.jpg"/>-->
			</div>
		</div>

		<!--			<div class="mt-4" v-if="!asNav">-->
		<!--				Spielerisch kann man sich ausrüsten, um aktiv in die Auseinandersetzung mit Aufgaben, die das Leben stellt, einzusteigen. Solche Aufgaben finden sich bei OmniMundi, dem Entfaltungsraum für Nachhaltige Bildung für alle. Wie Entfaltung dort auf frucht- und freudvolle Weise in Gang kommen und gelingen kann, mag jede:r ausprobieren und sich bei OmniMundi einloggen. Aufgaben statt Aufgeben ist dort die Strategie, mit der man wachsen kann und sich entfalten wird. AufgabenSalons, die OmniMundi anregen und gestalten will, möchten zeigen, wie ein Sich-Einlassen, Spuren zu hinterlassen vermag – bei jedem einzelnen und in der Gesellschaft. Wir arbeiten dran.-->
		<!--				<div @click="routeTo('/kompass/mitgedacht','Das Aufgabenspiel', 'bg-aufgaben')" class="bar bg-aufgaben is-size-4 mt-2 mb-4 ">-->
		<!--					<strong class="has-text-white">Mitgedacht &amp; Mitgemacht</strong>-->
		<!--					AufgabenSalons-->
		<!--				</div>-->
		<!--				<div>-->
		<!--					<h3>Dieses Format lädt dazu ein, sich mit komplexen Aufgaben so auseinanderzusetzen, dass man unter Bezug auf aufgabenbezogen versammelte Wissenseinheiten, Geschichten, Verfahren und Orientierungen mit einer fraglichen Sache, mit sich Selbst und mit anderen Menschen ins Gespräch und ins Tun kommt – spielerisch, aber auch praktisch und wirksam. Die Aufgaben-Salons verbinden in entfaltend moderierter Weise das Denken, Austauschen und Tun jeweils mit Liedern, mit Kunst und mit Wissenschaft.</h3>-->
		<!--				</div>-->
		<!--			</div>-->
	</div>


</template>
<script>
export default {
	name:  'CardGamesComponentOverview',
	props: {
		// show: {
		// 	type: Array,
		// 	default: ()=>[] // show sections 1...5, default = nil = all
		// }
	},
	data() {
		return {}
	},
	methods: {},
	mounted() {

	}
}
</script>

<style lang="scss" scoped>
.column img {
	max-width : 320px;
	@include tablets-and-up {
		width     : 47%;

		}
	}

</style>
