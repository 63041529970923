<template>

	<div class="container is-flex is-flex-direction-column is-full mb-6 pb-6">

		<h6 class="my-0">SPIELVARIANTE</h6>
		<h2 class="mt-0">Ausgewählt - das Berufsentwicklungsspiel</h2>

		<div class="columns is-mobile">
			<div class="column is-8 is-hidden-mobile">&nbsp;</div>
			<div class="column tab" :class="currentTab === 1 ? 'active' : ''"><a href="javascript:void(0)" @click.prevent="selectTab(1)">Berufe- und Studiensuche</a></div>
			<div class="column tab" :class="currentTab === 2 ? 'active' : ''"><a href="javascript:void(0)" @click.prevent="selectTab(2)">Spieldetails</a></div>
		</div>

		<!--	Search	-->
		<div v-show="currentTab===1">
			<div class=" is-flex is-flex-direction-column is-fullheight-with-navbar center">
				<div class="is-flex is-flex-direction-column is-full is-align-items-center mt-6 is-relative" style="width: 100%;">

					<article class="mb-6">
						<p>Wenn Du Karten Deines Lieblingsberufs gelegt hast, kannst Du den hier suchen und die Ergebnisse unseres Tools mit Deinen Karten vergleichen. Das funktioniert auch mit Studienplätzen, Praktika und Ehrenämtern. Probier's aus!</p>
					</article>

					<h6>Suche nach Beruf, Praktika oder Studium</h6>
					<input v-model="s" class="is-size-1 mb-4" type="search" @input="triggerSearch" value="mediz"/>
					<div>{{ s && !autoResults ? 'Oh, das finden wir nicht. Probiere etwas anderes - oder schreibe drei Buchstaben und schau, was wir finden.' : s && autoResults.length + ' Ergebnisse' }}</div>
					<div class="has-text-left">
						<div class="my-1 is-size-6 sres" v-for="r in autoResults" :key="r.profId" @click.prevent="loadProfile(r.profId)">
							{{ r.profName }}
						</div>
					</div>

					<!--					<p v-if="currentProfile ">{{ currentProfile.profType }}</p>-->
					<!--Todo: berufsfeld, not 16 !! -->
					<p v-if="currentProfile && currentProfile.profType === 16">Du hast ein Berufsfeld ausgewählt. Es werden nur Karten aus den Bereichen <strong>Aufgaben</strong> und <strong>Welten</strong> angezeigt.</p>
					<p v-if="currentProfile && currentProfile.profType === 2">Du hast ein Studium ausgewählt. Es werden nur Karten aus den Bereichen <strong>Aufgaben</strong> und <strong>Welten</strong> angezeigt.</p>
					<p v-if="currentProfile && currentProfile.profType === 8">Du hast ein Praktikum ausgewählt. Es werden nur Karten aus den Bereichen <strong>Aufgaben</strong> und <strong>Welten</strong> angezeigt.</p>

				</div>
			</div>

			<div class="has-text-left mt-6" v-if="currentProfile">
				<h2>{{ currentProfile.profName }}</h2>
				<div class="has-2-cols">
					<div v-html="currentProfile.profDescr"></div>
					<div v-html="currentProfile.profIntro"></div>
				</div>
				<hr/>
				<div class="welten is-relative" v-show="topRated && topRated[4]">
					<swiper
						:options="swiperOptions"
						class="has-text-left"
					>
						<swiper-slide v-for="(tr, index) in topRated[4]" :key="`tr-${tr.branchId}-${index}`">
							<div :class="`e-card e-card--${tr.value}`">
								<!--						<div class="card" v-for="answer in tr" :key="answer.id">-->
								<img :src="`//assets.entfalter.de/img/cards/${tr.id}.jpg`"/>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-button-prev is-hidden-mobile" slot="button-prev"></div>
					<div class="swiper-button-next is-hidden-mobile" slot="button-next"></div>
					<div class="swiper-pagination" slot="pagination"></div>

				</div>

				<div class="aufgaben is-relative" v-show="topRated && topRated[1]">
					<hr/>
					<swiper
						:options="$utils.getSwiperOptions({loop: false,
						pagination: {
						el: '.swiper-pagination--1'
						},
						navigation:    {
							nextEl: '.swiper-button-next--1',
							prevEl: '.swiper-button-prev--1'
						}})"
						class="has-text-left"
					>
						<swiper-slide v-for="(tr, index) in topRated[1]" :key="`tr-${tr.branchId}-${index}`">
							<div :class="`e-card e-card--${tr.value}`">
								<img :src="`//assets.entfalter.de/img/cards/${tr.id}.jpg`"/>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-button-prev swiper-button-prev--1 is-hidden-mobile" slot="button-prev"></div>
					<div class="swiper-button-next swiper-button-next--1 is-hidden-mobile" slot="button-next"></div>
					<div class="swiper-pagination swiper-pagination--1" slot="pagination"></div>

				</div>

				<div class="rollen is-relative" v-show="topRated && topRated[2]">
					<hr/>
					<swiper
						:options="$utils.getSwiperOptions({loop: false,
						pagination:    {
						el: '.swiper-pagination--2'
						},
						navigation:    {
							nextEl: '.swiper-button-next--2',
							prevEl: '.swiper-button-prev--2'
						}})"
						class="has-text-left"
					>

						<swiper-slide v-for="(tr, index) in topRated[2]" :key="`tr-${tr.branchId}-${index}`">
							<div :class="`e-card e-card--${tr.value}`">
								<img :src="`//assets.entfalter.de/img/cards/${tr.id}.jpg`"/>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-button-prev swiper-button-prev--2 is-hidden-mobile" slot="button-prev"></div>
					<div class="swiper-button-next swiper-button-next--2 is-hidden-mobile" slot="button-next"></div>
					<div class="swiper-pagination swiper-pagination--2" slot="pagination"></div>

				</div>

				<div class="arbeitsplatz is-relative" v-show="topRated && topRated[3]">
					<hr/>
					<swiper
						:options="$utils.getSwiperOptions({
						loop: false,
						pagination:    {
						el: '.swiper-pagination--3'
						},
						navigation:    {
							nextEl: '.swiper-button-next--3',
							prevEl: '.swiper-button-prev--3'
						}})"
						class="has-text-left"
					>
						<swiper-slide v-for="(tr, index) in topRated[3]" :key="`tr-${tr.branchId}-${index}`">
							<div :class="`e-card e-card--abl-${tr.value}`">
								<img :src="`//assets.entfalter.de/img/cards/${tr.id}.jpg`"/>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-button-prev swiper-button-prev--3 is-hidden-mobile" slot="button-prev"></div>
					<div class="swiper-button-next swiper-button-next--3 is-hidden-mobile" slot="button-next"></div>
					<div class="swiper-pagination swiper-pagination--3" slot="pagination"></div>

				</div>

			</div>
		</div>

		<!--	Info	-->
		<div v-show="currentTab===2" class="mt-8">
			<br/><br/><br/>
			<div class="pb-6 is-clearfix">
				<h3>Spieldetails</h3>
				<img class="is-pulled-left-tablet mr-6 mb-3" src="//assets.entfalter.de/img/cards-marketing/set-berufsentfaltung.jpg"/>
				<p>Man kann per Karten sowie mit der EntfalterApp individuelle Profile erstellen, indem man persönliche Vorlieben, Interessen und Aktionspotenziale zu Aufgaben, Weltbezügen, Rollen und Arbeitsplätzen als Kartenbild legt.</p>
				<p>
					<strong>Allein:</strong> All das kann man – als Besitzer des Entfalters – auch in der App anlegen und erhält dadurch ein persönliches Entfaltungsprofil zu den Bereichen Aufgaben, Rollen, Weltbezüge und Arbeitsplatzvorstellungen.
				</p>
				<p><strong>Mit vielen:</strong> Unterstützt durch die vielen Referenzprofile von Berufen, Ausbildungen und Ehrenämtern, die in der Entfalter-App dargestellt
				                                sind, kann man mit den Karten – allein oder mit anderen – Berufe, Ausbildungsangebote aber auch Stellenprofile von den Menschen am Tisch auslegen und ansehen.
				</p>
				<p> Sie werden damit bewusst, für alle konkreter vorstellbar und lassen sich für einen Austausch und Diskussionen mit einander vor Augen führen.
				    Das geht auch allein, ist aber in der Familie, mit Freunden und auch in größeren Runden, wie in einer Klasse, besonders interessant, weil so verschiedene Sichtweisen ins Spiel kommen.
				</p>
				<p>
					<strong>Als Spiel:</strong> Mit den Karten gelegte Profile lassen sich auch raten; man kann sie prüfend ansehen und diskutieren sowie mit den Profilen in der Entfalter-App abgleichen und dabei ggf. das bestehende Verständnis korrigieren und erweitern. Wenn man mag, kann man – ebenfalls spielerisch – die Profile von (öffentlichen) Personen darstellen, die man in seinem Umfeld oder aus Funk, Film, Fernsehen und Medien kennt. Sie lassen sich raten und diskutieren.
				</p>
				<p><strong>Für sich persönlich:</strong> Als der-/ diejenige, der/die das persönliche Entfaltungsprofil in der App angelegt hat, kann man dieses Profil für Matchings mit den Profilen von Berufen, Ausbildungen etc. nutzen und sieht so, welche Fülle an Möglichkeiten sich einem bietet.
				</p>
				<p>
					Das ist ein wichtiger Baustein, aus dem sich der persönliche Kompass in beruflicher Hinsicht ergibt, weil im Matching die individuellen Entfaltungsmöglichkeiten und -chancen sichtbar und diskutierbar werden. Denn in den entstehenden Matchings zeigt sich gut nachvollziehbar, was in welchen Prozentsätzen zum eigenen Profil passen könnte. Außerdem sieht man, woraus sich eine solche Passung genauer ergibt oder warum sie eher nicht entsteht.
				</p>
			</div>
		</div>


	</div>

</template>

<script>
import axios                   from "axios";
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
	name: 'spielBeruf',

	components: {
		swiper,
		swiperSlide
	},

	data() {
		return {
			currentTab:             1,
			s:                      null,
			autoResults:            [],
			currentProfile:         null,
			currentProfileBranches: null,
			topRated:               null,      // an object with 4 areas (branches) and n-questions
			swiperOptions:          this.$utils.getSwiperOptions({loop: false})
		}
	},

	mounted() {

	},

	computed: {},

	methods: {
		selectTab(which) {
			this.currentTab = which
		},

		async triggerSearch() {
			// this.s = this.$refs['s'].value
			if (this.s.length > 2) {
				const r = await axios.get(`${ process.env.VUE_APP_API_URL }searchProfiles&profName=${ this.s }`)
				if (r && r.data) {
					this.autoResults = r.data.profiles
				}
			}
		},

		async loadProfile(profId) {
			// Just open the app at this point. :: Add a cards-view maybe??
			// window.open(`https://app.entfalter.de/match/${profId}`, '_blank')

			// https://testcms.entfalter.de/service/eventHandlerDev.php?func=entfalter&task=getMatchDetails&userId=null&profId=613bd5e73ce649.97230461&matchBranchSel=1
			const r = await axios.get(`${ process.env.VUE_APP_API_URL }getMatchDetails&userId=null&profId=${ profId }&matchBranchSel=15`)
			if (r && r.data && r.data.result) {
				this.currentProfile = r.data.profile
				this.currentProfileBranches = r.data.branches
				// Get top-rated
				// this.topRated.aufgaben =
				this.topRated = this.getTopRatedProfAnswers(r.data)
				// Clear the search results
				this.autoResults = []
				this.s = null
			}
		},


		getTopRatedProfAnswers(data) {
			const topRated = {};

			data.branches.forEach(branch => {
				let allQueries = [];
				branch.topics.forEach(topic => {
					allQueries = allQueries.concat(topic.queries);
				});

				// Combine profAnsw1 and profAnsw2 from each query and keep track of their IDs and titles
				let profAnswers = allQueries.flatMap(query => {
					return +branch.branchId !== 3
						? {id: query.id, title: query.title, value: query.profAnsw2 || 0, branchId: branch.branchId, type: 'profAnsw2'}
						: {id: query.id, title: query.title, value: query.profAnsw1 || 0, branchId: branch.branchId, type: 'profAnsw1'}  // exception: abl. Values 0...4
				}).filter(pa => pa.value !== 0)

				// Todo: abl => profAnsw2

				// Sort the combined list by value in descending order and take the top 5
				let sortedProfAnswers = profAnswers
				.sort((a, b) => b.value - a.value)
				.slice(0, 8);

				// Todo: slice as per branch.
				// if (branch.branchId === 1)
				// 	sortedProfAnswers = sortedProfAnswers.slice(0, 2);
				// if (branch.branchId === 2)
				// 	sortedProfAnswers = sortedProfAnswers.slice(0, 3);
				// if (branch.branchId === 3)
				// 	sortedProfAnswers = sortedProfAnswers.slice(0, 4);
				// if (branch.branchId === 4)
				// 	sortedProfAnswers = sortedProfAnswers.slice(0, 1);

				// topRated[branch.title] = sortedProfAnswers;
				// Group the results by branchId
				if (!topRated[branch.branchId]) {
					topRated[branch.branchId] = []
				}
				topRated[branch.branchId].push(...sortedProfAnswers)
				//	console.log('Add TR ', topRated[branch.branchId])
			});

			return topRated;
		}


	},
	beforeDestroy() {

	}

}
</script>
<style lang="scss" scoped>

.tab a {
	text-decoration : underline;
	}

.tab.active a {
	font-weight     : bold;
	color           : grey;
	text-decoration : none;
	}

.swiper-pagination {
	margin-top : .5rem;
	}

.swiper-slide {
	padding-bottom : 0.5rem;
	}

.sres {
	cursor                     : pointer;
	min-width                  : 36em;
	padding                    : 1rem 2em 1rem 1rem;
	color                      : white;
	background                 : rgb(88, 136, 194);
	border-top-right-radius    : 2rem;
	border-bottom-right-radius : 2rem;

	}

.e-card {

	span {
		//		position  : absolute;
		top       : 50%;
		left      : 50%;
		transform : translate3d(-50%, -50%, 0);
		}

	// VARIANT 1:
	// Showing all cards, make the ones less important transparent
	// This will display a pyramid-like view, but it will lessen some other important ones
	//	.welten &:nth-child(n+2) {
	//		opacity : 0.2;
	//		}
	//
	//	.aufgaben &:nth-child(n+3) {
	//		opacity : 0.2;
	//		}
	//
	//	.rollen &:nth-child(n+4) {
	//		opacity : 0.2;
	//		}
	//
	//	.arbeitsplatz &:nth-child(n+5) {
	//		opacity : 0.2;
	//		}
	//

	// VARIANT 2:
	// Mark cards by importance
	&--6, &--abl-4 {
		opacity   : 0.8;
		filter    : grayscale(0.2);
		transform : scale(0.92);
		}

	&--5, &--abl-3 {
		opacity   : 0.6;
		filter    : grayscale(0.4);
		transform : scale(0.88);
		}

	&--4, &--abl-2 {
		opacity   : 0.5;
		filter    : grayscale(0.6);
		transform : scale(0.80);
		}

	&--3 {
		opacity   : 0.4;
		filter    : grayscale(0.8);
		transform : scale(0.72);
		}

	&--2 {
		opacity   : 0.3;
		filter    : grayscale(1);
		transform : scale(0.64);
		}

	&--1 {
		opacity   : 0.2;
		filter    : grayscale(1);
		transform : scale(0.5);
		}

	// Well, shitty api returns:
	&--null {
		//		display : none;
		border : 5px solid red;
		}


	transition : all 0.2s ease-in-out;

	//	&:hover {
	//		opacity   : 1;
	//		transform : scale(1.1);
	//		filter    : grayscale(0);
	//		z-index: 11;
	//		}

	}

</style>
