<template>
	<div class="page is-fullheight has-text-left">
		<div class="container content is-flex is-flex-direction-column is-full mb-6 pb-6">

			<h1 class="mb-1">Der Entfalter - Dein Kompass im Spiel des Lebens</h1>
			<!--			<p>Hier findest Du Spielanregungen und digitale Helfer. Wähle eine Spielart aus:</p>-->

			<div class="columns">
				<div class="column">
					<CardGamesComponent :as-nav="true"/>
				</div>
				<div class="column p-5">
					<div v-show="$route.name === 'kompass'">
						<h3>Willkommen! Wähle links eine Spielart.</h3>
						<p>Wir haben hier Spielvorschläge und interaktive Tools mit denen Du das Beste aus Deinem Kartenspiel herausholst. Bitte beachte, dass wir noch an diesen Seiten arbeiten. Wenn Du Wünsche oder Anregungen hast, <a href="mailto:info@omnimundi.de?subject=Entfalter Kompass">schreibe uns gerne eine Mail!</a></p>
						<img src="/img/kartenbox.jpg"/>
					</div>
					<div v-show="$route.name === 'berufsspiel'">
<!--						<ul class="nav mt-4">-->
<!--							<li>-->
<!--								<entfalter-button>Spieldetails</entfalter-button>-->
<!--							</li>-->
<!--							<li>-->
<!--								<entfalter-button>Suche Berufe</entfalter-button>-->
<!--							</li>-->

<!--						</ul>-->
					</div>
					<div v-show="$route.name === 'rollenspiel'">

					</div>
					<div v-show="$route.name === 'biografiespiel'">

					</div>
					<div v-show="$route.name === 'sprachspiel'">

					</div>
					<div v-show="$route.name === 'aufgabenspiel'">

					</div>


				</div>
			</div>

			<router-view></router-view>

		</div>
	</div>
</template>

<script>


import CardGamesComponent from "@/components/CardGames.vue";
// import EntfalterButton    from "@/components/entfalter-button.component.vue";

export default {
	name: 'spielDigital',

	components: {CardGamesComponent},

	data() {
		return {
			s:                      null,
			autoResults:            [],
			currentProfile:         null,
			currentProfileBranches: null,
			topRated:               null      // an object with 4 areas (branches) and n-questions
		}
	},

	mounted() {

	},

	computed: {},

	methods: {},
	beforeDestroy() {

	}

}
</script>
<style lang="scss">


</style>
