<template>

	<div class="content is-flex is-flex-direction-column is-full mb-6 pb-6">

		<h1>Aufgeräumt - das Rollenspiel</h1>

		<div>
		<img class="e-card is-pulled-left-tablet mr-6 mb-3" src="//assets.entfalter.de/img/cards-marketing/rollen.png"/>
		<p>Durch eine zu treffende Wahl aus dem Angebot der 24 alltagsrollen- und 24 gefühlsbezogenen Karten bekommt man heraus, wie man gerade in den alltäglichen Lebensrollen unterwegs ist und was insofern das persönliche Rollenpotential ausmacht.
		   Im Blick auf die Karten entsteht Übersicht und Ordnung sowie Ideen dazu, wie man sein Rollengefüge und Inneres Gefühlsteam stärken und vielleicht auch gezielter einsetzen kann.
		   Sein Inneres Team – wie der Kommunikationsforscher Schulz von Thun das nennt – zu kennen und einzuschätzen, hilft einem, seinen inneren Kompass, der manchmal wenig bewusst ist, zu kennen, ihn zu überprüfen und womöglich neu zu justieren.
		   Dazu lässt sich im spielerischen Tun, beim Rollen einnehmen und innere Teammitglieder verschieden zuordnen, herausfinden, was passt, was wie wirkt, was sich gut anfühlt und was einem und anderen gefällt.
		   Man sieht die große Zahl möglicher
		   Rollenspiele.</p>
		</div>

	</div>


</template>

<script>


export default {
	name: 'spielRollen',


}
</script>
<style lang="scss">


</style>
