<template>

	<div class="content is-flex is-flex-direction-column is-full mb-6 pb-6">

		<h1>Mitgedacht - das Aufgabenspiel</h1>


	</div>


</template>

<script>

export default {
	name: 'spielAufgaben',


}
</script>
<style lang="scss">


</style>
